import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import { Helmet } from "react-helmet"
import HubspotForm from "react-hubspot-form"
import Layout from "../components/layout"
import Shapefour from "../images/bannerElement/Contact-us.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import "../styles/contactus.css"

const ContactUs = ({ data }) => {
  return (
    <>
      {data.allWpPage.edges.map(({ node }) => (
        <Helmet>
          <title>{node.title} | Position2</title>
          <meta name="description" content={node.seo.metaDesc} />
          <body className={node.slug} />
        </Helmet>
      ))}

      <Layout>
        <section id="Banner" className="fintech">
          <div className="container">
            <div className="banner-holder">
              <div className="images">
                <div className="img-one image-shape">
                  <img src={Shapeone} alt="Image" />
                </div>
                <div className="img-two image-shape">
                  <img src={Shapetwo} alt="Image" />
                </div>
                <div className="img-three image-shape">
                  <img src={Shapethree} alt="Image" />
                </div>
                <div className="img-four image-shape">
                  <img src={Shapefour} alt="Image" />
                </div>
              </div>
              <div className="title">
                <div className="banner-title">
                  <h1>Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="Banner" className="no-cta">
          {data.allWpPage.edges.map(({ node }) => (
            <BannerComponent
              title={node.bannerSection.bannerTitle}
              dBanner={
                node.bannerSection.heroBannerImage.node.localFile.publicURL
              }
              mBanner={node.bannerSection.mobileBanner.node.localFile.publicURL}
              mAlt={node.bannerSection.mobileBanner.node.altText}
              dAlt={node.bannerSection.heroBannerImage.node.altText}
            />
          ))}
        </section> */}
        <section id="ContactUs">
          <div className="container">
            <div className="contact-holder">
              <div className="contact-form">
                <h2>
                  Share your details to allow us to contact you and address your
                  business requirements.
                </h2>
                <HubspotForm
                  region="na1"
                  portalId="2778625"
                  formId="19c3a959-7f3c-4c6e-af23-11d22ed5c067"
                  onSubmit={() => console.log("Submit!")}
                  onReady={form => console.log("Form ready!")}
                  loading={<div></div>}
                />
              </div>

              <div className="contact-location">
                {data.allWpPage.edges.map(({ node }) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: node.singleBlockLayoutSection.textArea,
                    }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default function ContactUsBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoxMjY1Nw==" } }) {
            edges {
              node {
                id
                title
                slug
                singleBlockLayoutSection {
                  textArea
                }
                seo {
                  metaDesc
                }
                bannerSection {
                  bannerTitle
                  heroBannerImage {
                    node {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                  mobileBanner {
                    node {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <ContactUs data={data} {...props} />}
    />
  )
}
ContactUs.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
